import { Box, Stack, StackDivider, Text } from '@chakra-ui/react';
import * as React from 'react';
import MainContainer from '../../../../../gatsby-theme-pega/src/components/container/container';
import { Copyright } from './Copyright';
import { FooterHeading } from './FooterHeading';
import { LinkGrid } from './LinkGrid';
import { Logo } from './Logo';
import { SocialMediaLinks } from './SocialMediaLinks';

const Footer = () => (
  <Box
    as="footer"
    role="contentinfo"
    mx="auto"
    maxW="7xl"
    py={12}
    px={{
      base: 8,
      lg: 4
    }}
  >
    <Stack spacing={10} divider={<StackDivider />}>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: '10', lg: '28' }}
      >
        <Stack flex="1" spacing="5">
          <Box h={14} w={14}>
            <Logo />
          </Box>
          <Stack maxW="sm">
            <Text textStyle="logo">CurrentClient</Text>
            <Text textStyle="accent">Nurture clients & Generate referrals</Text>
          </Stack>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '10', md: '20' }}
        >
          <LinkGrid spacing={{ base: '10', md: '20', lg: '28' }} flex="1" />
          {/* <SubscribeForm width={{ base: 'full', md: 'sm' }} /> */}
        </Stack>
      </Stack>
      <Stack
        direction={{ base: 'column-reverse', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Copyright />
        <SocialMediaLinks />
      </Stack>
    </Stack>
  </Box>
);

export default Footer;
