import { Flex, HTMLChakraProps, Image } from '@chakra-ui/react';
import React from 'react';
import CurrentClientLogo from '../../assets/CurrentClientLogo.png';

import { Link as GatsbyLink } from 'gatsby';

export const Logo = (props: HTMLChakraProps<'svg'>) => {
  return (
    <GatsbyLink to="/">
      <Flex items="center" justify="center" w="full" h="full">
        <Image mx="auto" h="full" src={CurrentClientLogo} alt="CC" />
      </Flex>
    </GatsbyLink>
  );
};
