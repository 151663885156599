import { useNavMenu } from './useNavMenu';
import {
  Text,
  HStack,
  Box,
  Collapse,
  SimpleGrid,
  useDisclosure,
  Badge
} from '@chakra-ui/react';
import * as React from 'react';

import { Link } from './_linkage';
import { NavLink } from './NavLink';
import { NavMenu } from './NavMenu';
import { SubmenuItem as DesktopMenuItem } from './SubmenuItem';
import { HiChevronDown } from '@meronex/icons/hi/';

interface SubmenuProps {
  link: Link;
}

const DesktopSubmenu = (props: SubmenuProps) => {
  const { link } = props;
  const { isOpen, getMenuProps, getTriggerProps } = useNavMenu();
  return (
    <>
      <NavLink.Desktop
        display="flex"
        alignItems="center"
        as="button"
        type="button"
        my={1} //  add some margin to allow for active state outline
        {...getTriggerProps()}
      >
        <Box>{link.label}</Box>
        <Box marginStart="2" as={HiChevronDown} fontSize="md" />
      </NavLink.Desktop>

      <NavMenu {...getMenuProps()} animate={isOpen ? 'open' : 'closed'}>
        <Box maxW="7xl" mx="auto" px="8">
          <SimpleGrid spacing="10" columns={2}>
            {link.children?.map((item, idx) => (
              <DesktopMenuItem
                key={idx}
                title={item.label}
                href={item.href}
                icon={item.icon}
                badge={item.badge}
              >
                {item.description}
              </DesktopMenuItem>
            ))}
          </SimpleGrid>
        </Box>
      </NavMenu>
    </>
  );
};

const MobileSubMenu = (props: SubmenuProps) => {
  const { link } = props;
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <NavLink.Mobile
        as="button"
        textAlign="start"
        type="button"
        cursor="pointer"
        onClick={onToggle}
        paddingEnd="4"
      >
        <Box flex="1">{link.label}</Box>
        <Box
          as={HiChevronDown}
          transform={`rotate(${isOpen ? '180deg' : '0deg'})`}
        />
      </NavLink.Mobile>
      <Collapse in={isOpen}>
        <Box pl="5">
          {link.children?.map((item, idx) => (
            <NavLink.Mobile key={idx} href={item.href}>
              <HStack>
                <Text fontWeight="normal">{item.label}</Text>
                {item.badge === 'soon' && (
                  <Badge colorScheme="sec">Coming Soon</Badge>
                )}
                {item.badge === 'new' && <Badge colorScheme="green">New</Badge>}
              </HStack>
            </NavLink.Mobile>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export const Submenu = {
  Mobile: MobileSubMenu,
  Desktop: DesktopSubmenu
};
