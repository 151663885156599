import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

interface ContainerProps {
  fullWidth?: boolean;
}

const MainContainer = ({
  children,
  pt = 0,
  pb = 0,
  px = {
    base: 8,
    lg: 4
  },
  fullWidth,
  ...rest
}: ContainerProps & Omit<FlexProps, 'css'>) => {
  return (
    <Flex
      maxWidth={fullWidth ? '100%' : '7xl'}
      width="100%"
      flexWrap="wrap"
      mx={[0, 'auto']}
      pt={pt}
      pb={pb}
      px={px}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default MainContainer;
