import {
  Box,
  Center,
  chakra,
  HStack,
  HTMLChakraProps,
  Badge,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import * as React from 'react';
import { HiArrowNarrowRight } from '@meronex/icons/hi/';

interface SubmenuItemProps extends HTMLChakraProps<'a'> {
  title: string;
  icon?: React.ReactElement;
  children: React.ReactNode;
  href: string;
  badge: 'soon' | 'new';
}

export const SubmenuItem = (props: SubmenuItemProps) => {
  const { title, icon, children, href, badge, ...rest } = props;
  return (
    <chakra.a
      className="group"
      href={href}
      m="-3"
      p="3"
      display="flex"
      alignItems="flex-start"
      transition="all 0.2s"
      rounded="lg"
      _hover={{ bg: mode('gray.50', 'gray.600') }}
      _focus={{ shadow: 'outline' }}
      {...rest}
    >
      <Center
        aria-hidden
        as="span"
        flexShrink={0}
        w="10"
        h="10"
        fontSize="3xl"
        color={mode('background200', 'gray.400')}
      >
        {icon}
      </Center>
      <Box marginStart="3" as="dl">
        <HStack as="dt" alignItems="center">
          <Text fontWeight="semibold" color={mode('gray.900', 'white')}>
            {title}
          </Text>
          {badge === 'soon' && <Badge colorScheme="sec">Coming Soon</Badge>}
          {badge === 'new' && <Badge colorScheme="green">New</Badge>}
          <Box
            fontSize="md"
            as={HiArrowNarrowRight}
            transition="all 0.2s"
            _groupHover={{
              color: mode('sec.600', 'inherit'),
              transform: 'translateX(2px)'
            }}
          />
        </HStack>
        <Text as="dd" color={mode('gray.500', 'gray.400')}>
          {children}
        </Text>
      </Box>
    </chakra.a>
  );
};
