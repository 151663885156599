import { ButtonGroup, ButtonGroupProps, IconButton } from '@chakra-ui/react';
import { FaGithub, FaLinkedin, FaTwitter, FaYoutube } from '@meronex/icons/fa';
import React from 'react';

export const SocialMediaLinks = (props: ButtonGroupProps) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton
      as="a"
      href="https://www.linkedin.com/company/currentclient"
      aria-label="LinkedIn"
      target="_blank"
      icon={<FaLinkedin fontSize="20px" />}
    />
    <IconButton
      as="a"
      href="https://www.youtube.com/channel/UCOCC_XWXvT-z3WZZ9RDigGg"
      aria-label="YouTube"
      target="_blank"
      icon={<FaYoutube fontSize="20px" />}
    />
  </ButtonGroup>
);
