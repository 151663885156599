import * as React from 'react';

import {
  HiOutlineCurrencyDollar,
  HiOutlineBriefcase,
  HiOutlineShieldCheck,
  HiOutlineScale,
  HiOutlinePlay,
  HiOutlineLibrary,
  HiOutlineCollection,
  HiOutlineShieldExclamation
} from '@meronex/icons/hi/';

export interface Link {
  label: string;
  href?: string;
  children?: Array<{
    label: string;
    description?: string;
    href: string;
    icon?: React.ReactElement;
    badge?: 'soon' | 'new';
  }>;
}

export const links: Link[] = [
  // { label: 'Training', href: '/docs' },
  // { label: 'Blog', href: '/blog' },
  { label: 'Features', href: '/features' },
  {
    label: 'Industries',
    children: [
      {
        label: 'Medicare',
        badge: 'soon',
        description: 'Read our documentation and FAQs, or get in touch.',
        href: '/',
        // href: '/industry/medicare',
        icon: <HiOutlineShieldCheck />
      },
      {
        label: 'Financial Planner',
        badge: 'soon',
        description: 'Discover and join your local Sketch community.',
        href: '/',
        // href: '/industry/financial-planner',
        icon: <HiOutlineBriefcase />
      },
      {
        label: 'CPA',
        badge: 'soon',
        description: 'Do even more with Assistants, plugins and integrations.',
        href: '/',
        // href: '/industry/cpa',
        icon: <HiOutlineCurrencyDollar />
      },
      {
        label: 'Attorney',
        badge: 'soon',
        description: 'Get updates, articles and insights from the team.',
        href: '/',
        // href: '/industry/attorney',
        icon: <HiOutlineScale />
      }
    ]
  },
  {
    label: 'Resources',
    children: [
      {
        label: 'Training',
        description: 'A place to learn how to use the app and best practices.',
        href: '/docs',
        icon: <HiOutlineLibrary />
      },
      {
        label: 'Videos',
        description: 'For the visual learners, check out our YouTube channel.',
        href:
          'https://www.youtube.com/channel/UCOCC_XWXvT-z3WZZ9RDigGg/featured',
        icon: <HiOutlinePlay />
      },
      {
        label: 'Blog',
        badge: 'new',
        description: 'Because its still cool to have a blog, right?',
        href: '/blog',
        icon: <HiOutlineCollection />
      },
      {
        label: 'Compliance',
        badge: 'soon',
        description: 'Compliance packages you can send to who needs it.',
        href: '/',
        icon: <HiOutlineShieldExclamation />
      }
    ]
  }
];
