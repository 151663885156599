import { Box, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';
import { NavContent } from './NavContent';

const Nav = () => {
  return (
    <Box
      as="header"
      bg={mode('white', 'gray.800')}
      position="relative"
      zIndex="10"
      sx={{
        top: 0,
        position: 'sticky'
      }}
      borderBottomWidth={1}
      borderBottomColor={mode('background100', 'brandSubtle')}
    >
      <Box
        as="nav"
        aria-label="Main navigation"
        maxW="7xl"
        mx="auto"
        px={{ base: '8', lg: '4' }}
      >
        <NavContent.Mobile display={{ base: 'flex', lg: 'none' }} />
        <NavContent.Desktop display={{ base: 'none', lg: 'flex' }} />
      </Box>
    </Box>
  );
};

export default Nav;
