import {
  Box,
  Link,
  SimpleGrid,
  SimpleGridProps,
  Stack,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { FooterHeading } from './FooterHeading';
import { graphql, Link as GatsbyLink } from 'gatsby';

export const LinkGrid = (props: SimpleGridProps) => (
  <SimpleGrid columns={2} {...props}>
    <Box minW="130px">
      <FooterHeading mb="4">Product</FooterHeading>
      <Stack>
        <Link as={GatsbyLink} to="/schedule-demo">
          Demo
        </Link>
        <Link as={GatsbyLink} to="/docs">
          Training
        </Link>
        <Link as={GatsbyLink} to="/blog">
          Blog
        </Link>
        <Link href="https://app.currentclient.com/auth/signin">Sign In</Link>
      </Stack>
    </Box>
    <Box minW="130px">
      <FooterHeading mb="4">Legal</FooterHeading>
      <Stack>
        <Link as={GatsbyLink} to="/terms-of-use">
          Terms
        </Link>
        <Link as={GatsbyLink} to="/privacy-policy">
          Privacy
        </Link>
      </Stack>
    </Box>
  </SimpleGrid>
);
