import {
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { NavLink } from './NavLink';
import { NavMenu } from './NavMenu';
import { Submenu } from './Submenu';
import { ToggleButton } from './ToggleButton';
import { links } from './_linkage';

const MobileNavContext = (props: FlexProps) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        className="nav-content__mobile"
        py={1}
        {...props}
      >
        <Box flexBasis="6rem">
          <ToggleButton isOpen={isOpen} onClick={onToggle} />
        </Box>
        <Button
          as={GatsbyLink}
          to="/"
          colorScheme="black"
          variant="link"
          fontWeight="bold"
          _hover={{
            textTransform: 'none'
          }}
        >
          <Text textStyle="logo">CurrentClient</Text>
        </Button>
        <Box visibility={{ base: 'hidden', sm: 'visible' }} flexBasis="6rem">
          <Button
            ml="auto"
            as="a"
            href="https://app.currentclient.com/auth/signin"
            variant="outline"
          >
            Sign In
          </Button>
        </Box>
      </Flex>
      <NavMenu animate={isOpen ? 'open' : 'closed'}>
        {links.map((link, idx) =>
          link.children ? (
            <Submenu.Mobile key={idx} link={link} />
          ) : (
            <NavLink.Mobile key={idx} href={link.href}>
              {link.label}
            </NavLink.Mobile>
          )
        )}
        <Button
          w="full"
          size="lg"
          mt="5"
          as="a"
          href="https://app.currentclient.com/auth/signup"
          colorScheme="pri"
        >
          Create an Account
        </Button>
        <Button
          w="full"
          size="lg"
          mt="5"
          as="a"
          colorScheme="pri"
          href="https://app.currentclient.com/auth/signin"
          variant="outline"
        >
          Sign in
        </Button>
      </NavMenu>
    </>
  );
};

const DesktopNavContent = (props: FlexProps) => {
  return (
    <Flex
      className="nav-content__desktop"
      align="center"
      justify="space-between"
      py={4}
      {...props}
    >
      <HStack spacing={8}>
        <Button
          as={GatsbyLink}
          to="/"
          colorScheme="black"
          variant="link"
          fontWeight="bold"
          _hover={{
            textTransform: 'none'
          }}
        >
          <Text textStyle="logo">CurrentClient</Text>
        </Button>

        <HStack
          as="ul"
          id="nav__primary-menu"
          aria-label="Main Menu"
          listStyleType="none"
        >
          {links.map((link, idx) => (
            <Box as="li" key={idx} id={`nav__menuitem-${idx}`}>
              {link.children ? (
                <Submenu.Desktop link={link} />
              ) : (
                <NavLink.Desktop href={link.href}>{link.label}</NavLink.Desktop>
              )}
            </Box>
          ))}
        </HStack>
      </HStack>

      <HStack spacing="8" minW="240px" justify="space-between">
        <Button
          as="a"
          href="https://app.currentclient.com/auth/signup"
          variant="outline"
        >
          Create Account
        </Button>
        <Button
          as="a"
          href="https://app.currentclient.com/auth/signin"
          colorScheme="pri"
        >
          Sign In
        </Button>
      </HStack>
    </Flex>
  );
};

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent
};
