import React, { ReactNode } from 'react';
import { Styled } from 'theme-ui';
import { ModalProvider } from 'react-modal-hook';

import '../global.scss';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';

export const CONTAIN_PADDING_X = {
  px: {
    base: 4,
    lg: 8
  }
};

const Layout = ({
  children,
  isShowFooter
}: {
  children: ReactNode;
  isShowFooter: boolean;
}) => {
  return (
    <Styled.root>
      <ModalProvider>
        <Navbar />
        {children}
        {isShowFooter && <Footer />}
      </ModalProvider>
    </Styled.root>
  );
};

export default Layout;
